import { TreeNode } from 'primeng/api';
import { BaseEntity, IBaseEntity } from './base.api';
import { IOrganization, Organization } from './common';

export interface IEnterpriseContext {
	industry: string[];
	revenue: string;
	information: string[];
	employee: string;
	country: string;
	topProducts: string[];
	topServices: string[];
	description: string;
	data_landscape?: any[];
}

export interface IImpactSimulationContext {
	breakthrough: number;
	transformative: number;
	incremental: number;
}

export interface ISimulationContext {
	aiEnablers: string[];
	technologies: string[];
	business_values: string[];
	impact: IImpactSimulationContext;
	taxonomy: string[];
	scope: string[];
	coldStorage: boolean;
	industries: string[];
}

interface IAssessmentDetails {
	total: string;
	finance: string;
	hr: string;
	it: string;
	marketing: string;
	procurement: string;
	sales: string;
	services: string;
	supplyChain: string;
}

interface ICounters {
	process: string;
	subProcess: string;
	e2e: string;
	steps: string;
	aiOpportunities: string;
	aiUseCases: string;
	breakthrough: IAssessmentDetails;
	transformative: IAssessmentDetails;
	incremental: IAssessmentDetails;
}

export interface IEnterpriseContextModel extends IBaseEntity {
	enterpriseContext: IEnterpriseContext;

	organization: IOrganization;
	organizationId: string;
}

export class EnterpriseContext extends BaseEntity implements IEnterpriseContextModel {
	organization: Organization;

	constructor(
		public enterpriseContext: IEnterpriseContext,
		public organizationId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			organization: { value: undefined, enumerable: false, writable: true },
		});
	}
}

export interface ISimulation extends IBaseEntity {
	name: string;
	description: string;
	notes: string;

	search: boolean;
	generate: boolean;

	taxonomyId?: string;
	taxonomyRel?: '1' | '2' | '3';

	enterpriseContext: IEnterpriseContext;
	simulationContext: ISimulationContext;

	organization: IOrganization;
	organizationId: string;
}

export class Simulation extends BaseEntity implements ISimulation {
	label: string;
	parent_label: string;
	use_cases: UseCaseSimulation[];

	organization: Organization;

	constructor(
		public name: string,
		public description: string,
		public notes: string,
		public search: boolean,
		public generate: boolean,
		public enterpriseContext: IEnterpriseContext,
		public simulationContext: ISimulationContext,
		public organizationId: string,
		public taxonomyId?: string,
		public taxonomyRel?: '1' | '2' | '3',
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			label: { value: undefined, enumerable: false, writable: true },
			parent_label: { value: undefined, enumerable: false, writable: true },
			use_cases: { value: [], enumerable: false, writable: true },
			organization: { value: undefined, enumerable: false, writable: true },
		});
	}

	get valids() {
		return (this.use_cases || []).filter((useCase) => useCase.valid);
	}
}

export interface IKeyFeature {
	name: string;
	help: string;
	description: string;
}

export interface IUseCaseContext {
	aiEnablers: string[];
	business_values: string[];
	tools: string[];
	impact: string[];
}

export interface IUseCaseSimulationData {
	[key: string]: any;

	llm_explanation: string;
	data_requirements: string;
	potential_challenges: string;

	process: string;
	subprocess: string;

	valuesDriversScores: {
		revenue_growth: number;
		customer_experience: number;
		process_productivity: number;
		employee_productivity: number;
		cost_savings: number;
	};
}

export interface IUseCaseSimulation extends IBaseEntity {
	name: string;
	description: string;

	keyFeatures: IKeyFeature[];
	context: IUseCaseContext;
	data: IUseCaseSimulationData;
	valid: boolean;
	type: '1' | '2';

	deployedId: string;

	simulationId: string;
	simulation: ISimulation;

	organization: IOrganization;
	organizationId: string;
}

export class UseCaseSimulation extends BaseEntity implements IUseCaseSimulation {
	simulation: Simulation;

	organization: Organization;

	constructor(
		public name: string,
		public description: string,
		public keyFeatures: IKeyFeature[],
		public context: IUseCaseContext,
		public data: IUseCaseSimulationData,
		public valid: boolean,
		public type: '1' | '2',
		public deployedId: string,
		public simulationId: string,
		public organizationId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			simulation: { value: undefined, enumerable: false, writable: true },
			organization: { value: undefined, enumerable: false, writable: true },
		});
	}
}

export interface IUseCaseLibraryData {
	[key: string]: any;

	levels2: string[];
	aiEnablers: string[];
	aiOpportunities: {
		name: string;
		description: string;
	}[];
	keyFeatures: {
		name: string;
		help: string;
		description: string;
	}[];
	valuesDriversScores: {
		revenue_growth: number;
		customer_experience: number;
		process_productivity: number;
		employee_productivity: number;
		cost_savings: number;
	};
	impact_assessment: string;
	data_requirements: string;
	potential_challenges: string;
}

export interface IUseCaseLibrary extends IBaseEntity {
	name: string;
	description: string;

	data: IUseCaseLibraryData;

	sourceId: string;
	sourceRel: '1' | '2' | '3' | '4';
}

export class UseCaseLibrary extends BaseEntity implements IUseCaseLibrary {
	constructor(
		public name: string,
		public description: string,
		public data: IUseCaseLibraryData,
		public sourceId: string,
		public sourceRel: '1' | '2' | '3' | '4',
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
	}
}

export interface IM1Prep extends IBaseEntity {
	organization: IOrganization;
	organizationId: string;

	context: {
		info: { name: string };
		additionalInformation: string;
		industryTreeSelected: TreeNode[];
		taxonomyTreeSelected: TreeNode[];
	};
	companyAnalysis: {
		summary?: string;
		industry?: string;
		earnings_call?: string;
		recent_news?: string;
		links?: string[];
	};
	competitiveLandscape: {
		summary?: string;
		key_competitors?: string[];
		links?: string[];
	};
	conversation: {
		introduction?: string;
		financial_highlights?: string;
		strategic_priorities?: string;
		key_initiatives_and_focus_areas?: string;
		product_portfolio_overview?: string;
		market_positioning_and_competitors?: string;
		sales_performance_and_growth_opportunities?: string;
		kpis_to_monitor?: string;
		industry_trends_to_watch?: string;
	};
	products: {
		products?: {
			name: string;
			description: string;
			channels: string[];
			segments: string[];
		}[];
		services?: {
			name: string;
			description: string;
			channels: string[];
			segments: string[];
		}[];
	};
	salesforce: any[];
}

export class M1Prep extends BaseEntity implements IM1Prep {
	organization: Organization;

	constructor(
		public context: {
			info: { name: string };
			additionalInformation: string;
			industryTreeSelected: TreeNode[];
			taxonomyTreeSelected: TreeNode[];
		},
		public companyAnalysis: {
			summary?: string;
			industry?: string;
			earnings_call?: string;
			recent_news?: string;
			links?: string[];
		},
		public competitiveLandscape: {
			summary?: string;
			key_competitors?: string[];
			links?: string[];
		},
		public conversation: {
			introduction?: string;
			financial_highlights?: string;
			strategic_priorities?: string;
			key_initiatives_and_focus_areas?: string;
			product_portfolio_overview?: string;
			market_positioning_and_competitors?: string;
			sales_performance_and_growth_opportunities?: string;
			kpis_to_monitor?: string;
			industry_trends_to_watch?: string;
		},
		public products: {
			products?: {
				name: string;
				description: string;
				channels: string[];
				segments: string[];
			}[];
			services?: {
				name: string;
				description: string;
				channels: string[];
				segments: string[];
			}[];
		},
		public salesforce: any[],
		public organizationId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			organization: { value: undefined, enumerable: false, writable: true },
		});
	}

	get _hasData() {
		return this.hasCompanyAnalysis && this.hasCompetitiveLandscape && this.hasConversation;
	}

	get hasData() {
		return this._hasData && this.hasProducts && this.hasServices;
	}

	get _hasAnyData() {
		return this.hasCompanyAnalysis || this.hasCompetitiveLandscape || this.hasConversation;
	}

	get hasAnyData() {
		return this._hasAnyData || this.hasProducts || this.hasServices;
	}

	get hasCompanyAnalysis() {
		return Object.keys(this.companyAnalysis).length;
	}

	get hasCompetitiveLandscape() {
		return Object.keys(this.competitiveLandscape).length;
	}

	get hasConversation() {
		return Object.keys(this.conversation).length;
	}

	get _hasProducts() {
		return Object.keys(this.products).length;
	}

	get hasProducts() {
		return this._hasProducts && this.products.products?.length;
	}

	get hasServices() {
		return this._hasProducts && this.products.services?.length;
	}

	get hasSalesforce() {
		return this.salesforce.length;
	}
}

export interface Counter {
	aiOpportunities: number;
	genAiOpportunities: number;
	embeddedAI: number;
	e2e: number;
	process: number;
	subProcess: number;
	genAiUseCases: number;
	aiUseCases: number;
	e2eSourceRel: number;
	processSourceRel: number;
	subProcessSourceRel: number;
	breakthrough: number;
	transformative: number;
	incremental: number;
	embedded?: any;
}
