<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="flex flex-column w-full gap-5 px-2">
	<div class="flex flex-wrap justify-content-start align-content-start">
		<div *ngIf="loaded && simulation" class="col-12 col-content">
			<div class="my-3 px-0">
				<label for="aiEnablers" class="text-md lg:text-lg xl:text-xl font-bold">Set parameters</label>
			</div>
			<div class="flex flex-wrap">
				<div class="col-6 p-0 pr-3">
					<div class="flex flex-wrap">
						<div class="col-12 px-0 pt-1">
							<div class="flex flex-column justify-content-start align-items-start gap-2">
								<label for="simulationName" class="font-bold label-filter-details"
									>Simulation Name</label
								>
								<input
									type="text"
									pInputText
									placeholder="Enter Simulation Name"
									id="simulationName"
									class="w-full"
									[(ngModel)]="simulation.name"
								/>
							</div>
						</div>
						<div class="col-6 px-0 pt-1">
							<div class="flex flex-column gap-3">
								<div class="flex flex-column justify-content-start align-items-start gap-2">
									<label for="aiEnablers" class="font-bold label-filter-details">AI Enablers </label>
									<p-multiSelect
										class="element-filter-100 pr-3 w-full"
										styleClass="filter-content min-token"
										[(ngModel)]="aiEnablersTreeSelections"
										[options]="simulationService.aiEnablersTreeOptions"
										display="chip"
										id="aiEnablers"
										inputId="aiEnablers"
										placeholder="Select AI Enabler"
										appendTo="body"
										[filter]="false"
										[showHeader]="false"
									></p-multiSelect>
								</div>
								<div class="flex flex-column justify-content-start align-items-start gap-2">
									<label for="benefits" class="font-bold label-filter-details">Benefits </label>
									<p-multiSelect
										class="element-filter-100 pr-3 w-full"
										styleClass="filter-content min-token"
										[(ngModel)]="businessValueDriversTreeSelections"
										[options]="simulationService.businessValueDriversTreeOptions"
										display="chip"
										id="benefits"
										inputId="benefits"
										placeholder="Select Benefits"
										appendTo="body"
										[filter]="false"
										[showHeader]="false"
									></p-multiSelect>
								</div>
							</div>
						</div>
						<div class="col-6 px-0 pt-1">
							<div class="flex flex-column gap-3">
								<div class="flex flex-column justify-content-start align-items-start gap-2 pl-3">
									<label for="taxonomy" class="font-bold label-filter-details">Taxonomy</label>
									<p-treeSelect
										class="w-full w-100 pr-1"
										containerStyleClass="w-full w-100"
										[(ngModel)]="taxonomyTreeSelections"
										[options]="simulationService.addProcessTaxonomyTreeSelect"
										placeholder="All"
										display="chip"
										[metaKeySelection]="false"
										selectionMode="checkbox"
										appendTo="body"
										id="taxonomy"
										inputId="taxonomy"
										[propagateSelectionUp]="false"
										(onNodeSelect)="updateTaxonomy()"
										(onNodeUnselect)="updateTaxonomy()"
									></p-treeSelect>
								</div>
								<div class="flex flex-column justify-content-start align-items-start gap-2 pl-3">
									<label for="scope" class="font-bold label-filter-details">Scope</label>
									<p-multiSelect
										class="element-filter-100 pr-1 w-full"
										styleClass="filter-content min-token"
										[(ngModel)]="scopeTreeSelections"
										[options]="simulationService.scopeTreeOptions"
										display="chip"
										id="scope"
										inputId="scope"
										placeholder="Select scope"
										appendTo="body"
										[filter]="false"
										[showHeader]="false"
									></p-multiSelect>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-6 p-0 pl-3">
					<div class="col-12 px-0 pt-1">
						<div class="flex flex-wrap">
							<div class="flex flex-column justify-content-start align-items-start gap-2 w-full">
								<label for="simulationDescription" class="font-bold label-filter-details"
									>Description</label
								>
								<textarea
									type="text"
									pInputTextarea
									placeholder="Enter description"
									id="simulationDescription"
									class="w-full"
									[(ngModel)]="simulation.description"
								></textarea>
							</div>
						</div>
					</div>
					<div class="col-12 px-0 pt-1">
						<div class="flex flex-wrap">
							<div class="col-3 px-0">
								<label for="aiEnablers" class="font-bold label-filter-details mb-2"
									>Use Cases By Impact Category</label
								>
								<div class="flex flex-column pt-3 gap-3">
									<div class="pr-3 flex align-items-center">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.breakthrough"
											inputId="breakthrough"
											id="breakthrough"
										></p-checkbox>
										<label for="breakthrough" class="pl-2">Breakthrough</label>
									</div>
									<div class="pr-3 flex align-items-center">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.transformative"
											inputId="transformative"
											id="transformative"
										></p-checkbox>
										<label for="transformative" class="pl-2">Transformative</label>
									</div>
									<div class="pr-3 flex align-items-center">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.incremental"
											inputId="incremental"
											id="incremental"
										></p-checkbox>
										<label for="incremental" class="pl-2">Incremental</label>
									</div>
								</div>
							</div>
							<div class="col px-0 ml-3">
								<div class="flex flex-column gap-3">
									<div class="flex align-items-center">
										<p-inputSwitch
											class="change-org-switch ml-3"
											inputId="hackettTaxonomy"
											id="hackettTaxonomy"
											[(ngModel)]="simulation.search"
										></p-inputSwitch>
										<label for="hackettTaxonomy" class="font-bold label-filter-details pl-2">
											Hackett Taxonomy Use Cases
										</label>
									</div>
									<div class="flex align-items-center">
										<p-inputSwitch
											class="change-org-switch ml-3"
											inputId="hackettIndustries"
											id="hackettIndustries"
											[(ngModel)]="simulation.generate"
										></p-inputSwitch>
										<label for="hackettIndustries" class="font-bold label-filter-details pl-2">
											Hackett Industry Use Cases
										</label>
									</div>
								</div>
							</div>
							<div class="col-5 px-0 ml-3">
								<div class="flex flex-column gap-3">
									<div class="flex align-items-center">
										<p-inputSwitch
											class="change-org-switch ml-3"
											inputId="coldStorage"
											id="coldStorage"
											[(ngModel)]="simulation.simulationContext.coldStorage"
										></p-inputSwitch>
										<label for="coldStorage" class="font-bold label-filter-details pl-2">
											Cold Storage
										</label>
									</div>
									<div
										*ngIf="simulation.simulationContext.coldStorage"
										class="flex flex-column justify-content-start align-items-start gap-2"
									>
										<p-multiSelect
											class="element-filter-100 pr-3 w-full"
											styleClass="filter-content min-token"
											[options]="industries"
											[(ngModel)]="simulation.simulationContext.industries"
											display="chip"
											id="industry"
											inputId="industry"
											placeholder="Select Industries"
											appendTo="body"
											[filter]="false"
											[showHeader]="false"
										></p-multiSelect>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 p-1 flex justify-content-end align-items-center pt-3 gap-3">
				<p-button
					label="Clear"
					icon="pi pi-eraser"
					styleClass="button-header"
					[disabled]="hasRunSimulation"
					[loading]="loadingSave"
					(onClick)="clearSimulation()"
				></p-button>
				<p-button
					label="Save"
					icon="pi pi-save"
					styleClass="button-header"
					[disabled]="hasRunSimulation"
					[loading]="loadingSave"
					(onClick)="saveSimulation()"
				></p-button>
				<p-button
					label="Run Simulation"
					icon="pi pi-play-circle"
					(onClick)="runSimulation()"
					[loading]="hasRunSimulation || loadingSave"
				></p-button>
			</div>

			<div *ngIf="!hasSimulation" class="box-simulation-item mt-3 py-3">
				<div class="flex flex-column justify-content-start gap-1">
					<div class="flex justify-content-between align-items-center">
						<span class="text-primary">Use Cases detail/overview</span>
					</div>
					<div
						*ngIf="!hasRunSimulation"
						class="flex justify-content-center align-items-center h-15rem text-color-normal"
					>
						<span class="text-empty">Set the parameters and run a simulation to see the use cases</span>
					</div>
					<div *ngIf="hasRunSimulation" class="mt-3">
						<p>
							<p-skeleton></p-skeleton>
						</p>
						<p>
							<p-skeleton></p-skeleton>
						</p>
						<p>
							<p-skeleton></p-skeleton>
						</p>
						<p>
							<p-skeleton></p-skeleton>
						</p>
						<p>
							<p-skeleton></p-skeleton>
						</p>
						<p>
							<p-skeleton></p-skeleton>
						</p>
					</div>
				</div>
			</div>
			<div *ngIf="hasSimulation" class="box-simulation-item mt-3 py-3">
				<div class="flex flex-column justify-content-start gap-1">
					<div class="flex justify-content-between align-items-center">
						<span class="text-primary">Use Cases detail/overview</span>
					</div>
					<div *ngIf="showUseCases">
						<div class="relative">
							<div class="absolute buttons-actions">
								<div class="flex justify-content-end gap-2 card p-0">
									<p-button
										*ngIf="dtSelection"
										icon="pi pi-trash"
										[label]="'Delete (' + dtSelection + ') selections'"
										styleClass="p-button-text"
										(onClick)="deleteUseCases()"
									></p-button>
								</div>
							</div>
						</div>
						<div *ngIf="hasRunSimulation" class="mt-3">
							<p>
								<p-skeleton></p-skeleton>
							</p>
							<p>
								<p-skeleton></p-skeleton>
							</p>
							<p>
								<p-skeleton></p-skeleton>
							</p>
							<p>
								<p-skeleton></p-skeleton>
							</p>
							<p>
								<p-skeleton></p-skeleton>
							</p>
							<p>
								<p-skeleton></p-skeleton>
							</p>
						</div>
						<div *ngIf="!hasRunSimulation">
							<table #tableUseCases></table>
						</div>
					</div>
					<div
						*ngIf="!showUseCases && !hasRunSimulation"
						class="flex justify-content-center align-items-center h-15rem text-color-normal"
					>
						<span class="text-empty">Set the parameters and run a simulation to see the use cases</span>
					</div>
				</div>
			</div>

			<div *ngIf="loaded && !simulation" class="col-12 col-content">
				<div class="flex justify-content-center align-items-center h-25rem text-color-normal">
					<div class="flex flex-column gap-3 align-content-center justify-content-center text-center">
						<div class="w-full text-center">
							<img src="assets/rtp/img/simulations-empty.png" alt="" class="w-5rem" />
						</div>
						<span class="text-lg text-primary">No simulation found</span>
					</div>
				</div>
			</div>
			<div *ngIf="!loaded" class="col-12 col-content">
				<div class="flex justify-content-center align-items-center h-25rem text-color-normal">
					<i class="pi pi-spin pi-spinner text-primary" style="font-size: 3.5rem"></i>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Use Case" [(visible)]="displayModal" [modal]="true" [style]="{ width: '80vw' }" (onHide)="onClose()">
	<app-use-case-simulation-item
		*ngIf="currentUseCase"
		[useCase]="currentUseCase"
		[useCasesSaved]="useCasesSaved"
	></app-use-case-simulation-item>
</p-dialog>

<p-dialog
	header="Saving Simulation..."
	[visible]="loadingSave && !hasRunSimulation"
	[modal]="true"
	[style]="{ width: '50vw' }"
>
	<div class="flex justify-content-center align-items-center h-5rem w-full">
		<i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
	</div>
</p-dialog>
